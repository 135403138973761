/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames";
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react";
import Feedback from "./Feedback";
import Hint from "./Hint";
import Label from "./Label";
import { Status } from "./types";

type Props = {
  label?: string;
  hint?: string;
  status?: Status;
  feedback?: ReactNode;
  suppressHotjar?: boolean;
  textAreaClassName?: string;
} & ComponentPropsWithoutRef<"textarea">;

// eslint-disable-next-line no-underscore-dangle
const _TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      id,
      label = "",
      hint = "",
      status = "default",
      feedback = "",
      disabled,
      className,
      suppressHotjar,
      textAreaClassName,
      ...props
    },
    ref
  ) => {
    const statusColor: Record<string, string> = {
      default:
        "border-other-light-100 focus:border-primary-bold focus:shadow-primary-bold/8",
      error: "border-error focus:border-error focus:shadow-error/8",
    };

    const textClasses = classNames(
      "body-3 form-input bg-white border rounded-lg w-full block focus:outline-none focus:ring-0 focus:shadow-input",
      {
        "bg-background-light-300 disabled:border-other-light-100": disabled,
      },
      statusColor[status] || statusColor.default,
      textAreaClassName
    );

    return (
      <div className={className}>
        <div className="flex flex-row justify-between">
          <div
            className={classNames(
              "flex-initial text-text-light-100 caption-1",
              {
                "basis-1/2": hint,
              }
            )}
          >
            {label && <Label id={id} label={label} />}
          </div>
          {hint && <Hint hint={hint} />}
        </div>
        <div className="py-1">
          <textarea
            id={id}
            ref={ref}
            className={textClasses}
            disabled={disabled}
            data-hj-allow={!suppressHotjar}
            {...props}
            aria-label={`Enter ${label}`}
          />
        </div>
        {feedback && <Feedback status={status}>{feedback}</Feedback>}
      </div>
    );
  }
);

export default _TextArea;
