import _Checkbox from "./Checkbox";
import _Input from "./Input";
import _Radio from "./Radio";
import _TextArea from "./TextArea";
import _Range from "./Slider";
import _Password from "./Password";
import _FirstName from "./FirstName";
import _LastName from "./LastName";
import _Email from "./Email";
import _Phone from "./Phone";
import _PostalCode from "./PostalCode";

const Form = {
  FirstName: _FirstName,
  LastName: _LastName,
  Email: _Email,
  Phone: _Phone,
  PostalCode: _PostalCode,
  Input: _Input,
  TextArea: _TextArea,
  Checkbox: _Checkbox,
  Radio: _Radio,
  Range: _Range,
  Password: _Password,
};

export default Form;
