import { forwardRef, useState } from "react";
import { BaseInputProps } from "@common/types/FormInputProps";
import Input from "./Input";
import { VisibilityOffIcon, VisibilityOnIcon } from "../icons";

// eslint-disable-next-line no-underscore-dangle
const _Password = forwardRef<HTMLInputElement, BaseInputProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const iconClass = "h-6 w-6 hover:text-gray-800";

  const getIcon = () =>
    showPassword ? (
      <VisibilityOnIcon
        className={iconClass}
        onClick={() => setShowPassword(!showPassword)}
      />
    ) : (
      <VisibilityOffIcon
        className={iconClass}
        onClick={() => setShowPassword(!showPassword)}
      />
    );

  return (
    <Input
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      type={showPassword ? "text" : "password"}
      ref={ref}
      rightIconClickable
      rightIcon={getIcon()}
      suppressHotjar
    />
  );
});

export default _Password;
