import { IMask } from "react-imask";
import { ChangeEvent, FormEvent, useState } from "react";
import { useRouter } from "next/router";
import { DateTimeFormatter, LocalDate, nativeJs } from "@js-joda/core";

type MaskNumberOption = Omit<IMask.MaskedNumberOptions, "mask">;

const useMask = (option: IMask.AnyMaskedOptions) => {
  const [maskOption] = useState(option);

  const maskInstance = IMask.createMask(maskOption);

  const maskPipe = IMask.createPipe(
    maskInstance,
    IMask.PIPE_TYPE.UNMASKED,
    IMask.PIPE_TYPE.MASKED
  );
  const unMaskPipe = IMask.createPipe(
    maskInstance,
    IMask.PIPE_TYPE.MASKED,
    IMask.PIPE_TYPE.UNMASKED
  );

  const onChange = (
    e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>
  ) => {
    const unmasked = unMaskPipe((e.target as HTMLInputElement).value);
    const newValue = maskPipe(unmasked);
    (e.target as HTMLInputElement).value = newValue;
  };

  return {
    maskOption,
    maskInstance,
    onChange,
    maskPipe,
    unMaskPipe,
  };
};

const removeLeadingZeros = (value: string): string => {
  return value.replace(/^[0\D]+(\d)/, "$1");
};

export function useNullableNumberMask(option?: MaskNumberOption) {
  const { locale } = useRouter() || {};
  const maskOptions = {
    mask: Number,
    thousandsSeparator: ",",
    ...option,
  };
  const enNumberMask = useMask(maskOptions);
  const frNumberMask = useMask({
    ...maskOptions,
    thousandsSeparator: " ",
  });

  const mask = locale === "fr" ? frNumberMask : enNumberMask;
  return {
    ...mask,
    maskPipe: (input: string) => (input !== "" ? mask.maskPipe(input) : ""),
    unMaskPipe: (input: string) =>
      input !== "" ? +mask.unMaskPipe(input) : null,
  };
}

export function useNumberMask(option?: MaskNumberOption) {
  const { locale } = useRouter() || {};
  const maskOptions = {
    mask: Number,
    thousandsSeparator: ",",
    scale: 0,
    padFractionalZeros: false,
    ...option,
  };
  const enNumberMask = useMask(maskOptions);
  const frNumberMask = useMask({
    ...maskOptions,
    thousandsSeparator: " ",
  });

  const mask = locale === "fr" ? frNumberMask : enNumberMask;
  return {
    ...mask,
    unMaskPipe: (input: string) => +mask.unMaskPipe(removeLeadingZeros(input)),
  };
}

export function useDecimalMask(options?: MaskNumberOption) {
  const { locale } = useRouter() || {};
  const maskOptions = {
    mask: Number,
    scale: 2,
    thousandsSeparator: ",",
    padFractionalZeros: true,
    radix: ".",
    mapToRadix: ["."],
    ...options,
  };

  const enDecimalMask = useMask(maskOptions);
  const frDecimalMask = useMask({
    ...maskOptions,
    thousandsSeparator: " ",
    radix: ",",
  });

  const mask = locale === "fr" ? frDecimalMask : enDecimalMask;
  return {
    ...mask,
    unMaskPipe: (input: string) => +mask.unMaskPipe(input),
  };
}

export function usePhoneNumberMask() {
  return useMask({
    mask: "1 (000) 000-0000",
  });
}

export function useAlphaMask() {
  return useMask({
    mask: /^[a-zA-Z]*$/,
  });
}

export function useUppercaseAlphaNumericMask() {
  return useMask({
    mask: /^[0-9a-zA-Z]*$/,
    prepare: (str: string) => {
      return str.toUpperCase();
    },
  });
}

export function usePostalCodeMask() {
  return useMask({
    mask: "a0a 0a0",
    prepare: (str: string) => {
      return str.toUpperCase();
    },
  });
}

export function useDateMask(minDate?: Date | null, maxDate?: Date | null) {
  const dateFormat = "MM/dd/yyyy";
  const [min] = useState<Date>(minDate || new Date(1990, 0));
  const [max] = useState<Date>(maxDate || new Date());

  return useMask({
    mask: Date,
    pattern: dateFormat,
    min,
    max,
    format: (date: Date) => {
      return LocalDate.from(nativeJs(date)).format(
        DateTimeFormatter.ofPattern(dateFormat)
      );
    },
    parse: (date: string) => {
      return new Date(date);
    },
    blocks: {
      yyyy: {
        mask: IMask.MaskedRange,
        from: min.getFullYear() - 1,
        to: max.getFullYear(),
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
      },
    },
  });
}

export function useDateTimeMask(minDate?: Date | null, maxDate?: Date | null) {
  const [min] = useState<Date>(minDate || new Date(1990, 0));
  const [max] = useState<Date>(maxDate || new Date());

  return useMask({
    mask: "MM/dd/yyyy hh:mm aa",
    blocks: {
      yyyy: {
        mask: IMask.MaskedRange,
        from: min.getFullYear() - 1,
        to: max.getFullYear(),
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
      dd: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
      },
      hh: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 12,
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
      },
      aa: {
        mask: IMask.MaskedEnum,
        enum: ["AM", "am", "PM", "pm"],
      } as unknown as IMask.MaskedEnumOptions,
    },
  });
}

export function useMonthMask(minDate?: Date | null, maxDate?: Date | null) {
  const [min] = useState<Date>(minDate || new Date(1990, 0));
  const [max] = useState<Date>(maxDate || new Date());

  return useMask({
    mask: "MM/yyyy",
    blocks: {
      yyyy: {
        mask: IMask.MaskedRange,
        from: min.getFullYear(),
        to: max.getFullYear(),
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
      },
    },
  });
}

export default useMask;
