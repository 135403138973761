import FSAGeographicArea from "@common/types/FSAGeographicArea";
import { Province } from "@common/types/Province";
import { convert, LocalDate } from "@js-joda/core";

const firstName = {
  maxLength: 50,
  required: true,
  validate: {
    valid: (value?: string | null) => {
      if (value && value.length > firstName.maxLength) return false;

      return true;
    },
  },
};

const lastName = {
  maxLength: 50,
  required: true,
  validate: {
    valid: (value?: string | null) => {
      if (value && value.length > lastName.maxLength) return false;

      return true;
    },
  },
};

const email = {
  maxLength: 254,
  required: true,
  pattern: /^[\w._+-]+@[\w._]+\.\w+$/,
  validate: {
    valid: (value?: string | null) => {
      if (value) {
        if (value.length > email.maxLength) return false;
        return email.pattern.test(value);
      }

      return true;
    },
  },
};

const phoneNumber = {
  maxLength: 16,
  valueLength: 10,
  required: true,
  validate: {
    valid: (value?: string | null) => {
      if (value) {
        if (value.length > phoneNumber.maxLength) return false;
        // remove all non-numeric characters, remove leading 1
        const rawPhoneNumber = value.replace(/\D/g, "").replace(/^1/, "");
        return rawPhoneNumber.length === phoneNumber.valueLength;
      }

      return true;
    },
    validAreaCode: (value?: string | null) => {
      if (value) {
        // remove all non-numeric characters, remove leading 1
        const rawPhoneNumber = value.replace(/\D/g, "").replace(/^1/, "");
        const [areaCode] = rawPhoneNumber.split("");

        return +areaCode > 1;
      }

      return true;
    },
  },
};

const DEFAULT_MIN_AGE = 18;
const DEFAULT_MAX_AGE = 120;
const dateOfBirth = {
  minAge: DEFAULT_MIN_AGE,
  minAgeToBuyCoverage: 16,
  maxAge: DEFAULT_MAX_AGE,
  getMinLocalDate: (today: LocalDate, maxAge = DEFAULT_MAX_AGE) =>
    today.minusYears(maxAge),
  getMaxLocalDate: (today: LocalDate, minAge = DEFAULT_MIN_AGE) =>
    today.minusYears(minAge),
  getMinDate: (today: LocalDate, maxAge = DEFAULT_MAX_AGE) =>
    convert(dateOfBirth.getMinLocalDate(today, maxAge)).toDate(),
  getMaxDate: (today: LocalDate, minAge = DEFAULT_MIN_AGE) =>
    convert(dateOfBirth.getMaxLocalDate(today, minAge)).toDate(),
};

const streetAddress = {
  maxLength: 254,
};

const city = {
  maxLength: 50,
};

const postalCode = (province?: Province | null) => {
  return {
    pattern:
      /**
       * Canadian postal code regex:
       *
       * Optional space in between 3 characters
       *
       * Disallows:
       * - Leading Z or W
       * - D, F, I, O, Q, U
       */
      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d$/,
    maxLength: 7,
    validate: {
      matchingProvince: (value?: string | null) => {
        if (value) {
          return province?.fsaAreas.includes(value[0] as FSAGeographicArea);
        }

        return true;
      },
    },
  };
};

const customerValidation = {
  firstName,
  lastName,
  email,
  phoneNumber,
  dateOfBirth,
  streetAddress,
  city,
  postalCode,
};

export default customerValidation;
