import { formatCurrency } from "@common/helpers/numberFormatting";
import dynamic from "next/dynamic";
import { forwardRef, HTMLProps } from "react";

const ReactSlider = dynamic(import("react-slider"), { ssr: false });

type SliderFormatDisplay = "none" | "locale" | "money";

type SliderProps<T extends number | ReadonlyArray<number> = number> = {
  ariaLabel?: T extends number ? string : ReadonlyArray<string> | undefined;
  ariaLabelledby?: T extends number
    ? string
    : ReadonlyArray<string> | undefined;
  ariaValuetext?:
    | string
    | ((value: { index: number; value: T; valueNow: number }) => string)
    | undefined;
  className?: string | undefined;
  defaultValue?: T | undefined;
  disabled?: boolean | undefined;
  invert?: boolean | undefined;
  markClassName?: string | undefined;
  marks?: boolean | number | ReadonlyArray<number> | undefined;
  max?: number | undefined;
  min?: number | undefined;
  minDistance?: number | undefined;
  onAfterChange?: ((value: T, index: number) => void) | undefined;
  onBeforeChange?: ((value: T, index: number) => void) | undefined;
  onChange?: ((value: T, index: number) => void) | undefined;
  onSliderClick?: ((value: number) => void) | undefined;
  orientation?: "horizontal" | "vertical" | undefined;
  pageFn?: ((step: number) => number) | undefined;
  pearling?: boolean | undefined;
  renderMark?:
    | ((props: HTMLProps<HTMLSpanElement>) => JSX.Element | null)
    | undefined;
  renderThumb?:
    | ((
        props: HTMLProps<HTMLDivElement>,
        state: { index: number; value: T; valueNow: number }
      ) => JSX.Element | null)
    | undefined;
  renderTrack?:
    | ((
        props: HTMLProps<HTMLDivElement>,
        state: { index: number; value: T }
      ) => JSX.Element | null)
    | undefined;
  snapDragDisabled?: boolean | undefined;
  step?: number | undefined;
  thumbActiveClassName?: string | undefined;
  thumbClassName?: string | undefined;
  trackClassName?: string | undefined;
  value?: T | undefined;
  withTracks?: boolean | undefined;
  formatDisplayValue?: SliderFormatDisplay;
  locale?: string;
  showSubText?: boolean;
};

const Slider = forwardRef<
  HTMLInputElement,
  SliderProps<number | ReadonlyArray<number>>
>(
  (
    {
      ariaLabel,
      ariaLabelledby,
      ariaValuetext,
      className = "horizontal-slider",
      defaultValue,
      disabled = false,
      invert = false,
      markClassName,
      marks = false,
      max,
      min,
      minDistance,
      onAfterChange,
      onBeforeChange,
      onChange,
      onSliderClick,
      orientation = "horizontal",
      pageFn,
      pearling,
      snapDragDisabled,
      step = 1,
      thumbActiveClassName,
      thumbClassName = "slider-thumb",
      trackClassName = "slider-track",
      value,
      withTracks = true,
      renderMark,
      renderThumb,
      renderTrack,
      formatDisplayValue = "none",
      locale = "en",
      showSubText = true,
    }: SliderProps<number | ReadonlyArray<number>>,
    ref
  ) => {
    const formatSliderValue = (
      formatType: string,
      sliderValue: number
    ): string => {
      if (formatType === "locale") {
        return sliderValue.toLocaleString(locale === "fr" ? "fr-CA" : "en-CA");
      }

      if (formatType === "money") {
        return formatCurrency(sliderValue, locale, { showCents: "never" });
      }

      return sliderValue.toString();
    };

    return (
      <div className="flex flex-col gap-3.5">
        <div>
          <ReactSlider
            ariaLabel={ariaLabel}
            ariaLabelledby={ariaLabelledby}
            ariaValuetext={ariaValuetext}
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            invert={invert}
            markClassName={markClassName}
            marks={marks}
            max={max}
            min={min}
            minDistance={minDistance}
            onAfterChange={onAfterChange}
            onBeforeChange={onBeforeChange}
            onChange={onChange}
            onSliderClick={onSliderClick}
            orientation={orientation}
            pageFn={pageFn}
            pearling={pearling}
            snapDragDisabled={snapDragDisabled}
            step={step}
            thumbActiveClassName={thumbActiveClassName}
            thumbClassName={thumbClassName}
            value={value}
            withTracks={withTracks}
            trackClassName={trackClassName}
            renderThumb={renderThumb}
            renderMark={renderMark}
            renderTrack={renderTrack}
          />
        </div>

        <div className="pt-3">
          {Array.isArray(value) && Array.isArray(defaultValue) && (
            <>
              {value.map((sliderValue, index) => (
                <span
                  key={sliderValue}
                  className={`caption-1  ${
                    sliderValue === defaultValue[index]
                      ? "text-text-light-200"
                      : "text-primary-bold"
                  }`}
                >
                  {formatSliderValue(formatDisplayValue, sliderValue as number)}
                  {value.length !== index + 1 ? " - " : ""}
                </span>
              ))}
              <input
                type="hidden"
                value={value ? value?.map(String) : undefined}
                ref={ref}
              />
            </>
          )}

          {!Array.isArray(value) && showSubText && (
            <>
              <p
                className={`caption-1  ${
                  value === defaultValue
                    ? "text-text-light-200"
                    : "text-primary-bold"
                }`}
              >
                {formatSliderValue(formatDisplayValue, value as number)}
              </p>
              <input
                type="hidden"
                value={value ? +value : undefined}
                ref={ref}
              />
            </>
          )}
        </div>
      </div>
    );
  }
);

export default Slider;
