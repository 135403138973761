import { forwardRef } from "react";
import { BaseInputProps } from "@common/types/FormInputProps";
import getInputStyles from "@common/helpers/form/getInputStyles";
import getBaseInputProps from "@common/helpers/form/getBaseInputProps";
import BaseInput from "./BaseInput";

// eslint-disable-next-line no-underscore-dangle
const _Input = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      type = "text",
      id,
      disabled,
      className,
      suppressHotjar,
      fieldError,
      onChange,
      ...props
    },
    ref
  ) => {
    const baseInputProps = getBaseInputProps({
      type,
      disabled,
      status: fieldError ? "error" : "default",
      feedback: fieldError?.message,
      ...props,
    });
    const inputClassName = getInputStyles({
      disabled: baseInputProps.disabled,
      leftIcon: baseInputProps.leftIcon,
      rightIcon: baseInputProps.rightIcon,
      border: baseInputProps.border,
      placeholderSize: baseInputProps.placeholderSize,
      status: baseInputProps.status,
    });
    return (
      <BaseInput id={id} className={className} {...baseInputProps}>
        <input
          id={id}
          className={inputClassName}
          type={type}
          disabled={disabled}
          ref={ref}
          data-hj-allow={(!suppressHotjar && type === "text") || undefined}
          onChange={(e) => {
            e.target.value = e.target.value.trimStart();
            if (onChange) onChange(e);
          }}
          {...props}
        />
      </BaseInput>
    );
  }
);

export default _Input;
