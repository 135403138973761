import classNames from "classnames";

type HintProps = {
  hint?: string;
  align?: "right" | "left";
};

const Hint = ({ hint, align = "right" }: HintProps) => {
  return (
    <span
      id="input-hint"
      className={classNames(
        "flex-initial basis-1/2 caption-2 text-text-light-200",
        { "pl-2 text-right": align === "right" },
        { "pl-0 text-left": align === "left" }
      )}
    >
      {hint}
    </span>
  );
};

export default Hint;
