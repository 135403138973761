import { BaseInputProps } from "@common/types/FormInputProps";

const getBaseInputProps = (
  overrides: Partial<BaseInputProps> = {}
): BaseInputProps => {
  const defaultProps: BaseInputProps = {
    type: "text",
    label: "",
    hint: "",
    status: "default",
    feedback: "",
    subLabel: "",
    leftIconClickable: false,
    rightIconClickable: false,
    border: "normal",
    placeholderSize: "medium",
    labelTextStyle: "gray",
    isOptional: false,
    hidden: false,
  };

  return { ...defaultProps, ...overrides };
};

export default getBaseInputProps;
